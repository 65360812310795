
.main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.main-tab {
    font-size: 16px;
    line-height: 1;
    border-bottom: 1px solid #F1F5FF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 39px;
    position: relative;

    .right {
        position: absolute;
        top: -10px;
        right: 0;
    }
}

.a-link {
    color: inherit;
}
